import { graphql, Script } from 'gatsby';
import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TallyButton from '../components/academy/TallyButton';
import MeetButton from '../components/buttons/MeetButton';
import Banner from '../components/clients/Banner';
import DefaultLayout from '../components/layouts/default-layout';
import * as Colors from '../components/Colors';
import SvgDownArrow from '../images/icons/downArrow.inline.svg';
import type { AllImage, AllMdxJob, PageContextProps } from '../utils/graphql';
import useElementOnScreen from '../utils/useElementOnScreen';

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "clients" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
      }
    }
  }
`;

type OurCurrentSponsorshipQueryProps = {
  allFile: AllImage;
  allMdx: AllMdxJob;
};

type OurCurrentSponsorshipProps = PageProps<OurCurrentSponsorshipQueryProps, PageContextProps>;

const OurCurrentSponsoring: React.VoidFunctionComponent<OurCurrentSponsorshipProps> = ({
  data: {
    allFile: { nodes: nodeFile },
  },
  pageContext,
}) => {
  const { t: tLanding } = useTranslation('landing');
  const { t: tEvents } = useTranslation('events');
  const [animatedIntroduction, isInViewIntroduction] = useElementOnScreen<HTMLDivElement>();
  const [animatedSafe, isInViewSafe] = useElementOnScreen<HTMLDivElement>();
  const [animatedReacteev, isInViewReacteev] = useElementOnScreen<HTMLDivElement>();
  const [animatedMeet, isInViewMeet] = useElementOnScreen<HTMLDivElement>();
  const [animatedSponsor, isInViewSponsor] = useElementOnScreen<HTMLDivElement>();

  const animateSlideInClass = (isInView: boolean) => {
    if (isInView) {
      return 'animate-slidein';
    }
    return '';
  };

  return (
    <DefaultLayout
      headTitle={tEvents('head-title')}
      headDescription={tEvents('head-description')}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLACK, pseudoClass: Colors.WHITE_ON_BLACK_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
    >
      <Script src="https://tally.so/widgets/embed.js" />
      <div className="h-screen w-full">
        <header
          className={`relative flex h-screen w-full snap-start flex-wrap justify-center text-center ${Colors.WHITE_ON_BLACK}`}
        >
          <div
            className={`translate-y-12 self-center px-4 opacity-0 animate-delay-75 sm:px-8 ${animateSlideInClass(
              isInViewIntroduction,
            )}`}
            ref={animatedIntroduction}
          >
            <h1>{tEvents('header.title')}</h1>
            <h2>{tEvents('header.description')}</h2>
          </div>
          <LocalizedLink
            className="absolute bottom-12 animate-bounce text-center"
            title={tLanding('introduction.discover')}
            to="/our-current-sponsorship/#safe"
            rel="nofollow"
          >
            <SvgDownArrow className="mx-auto block" />
            <p className="mt-3 font-medium">{tLanding('introduction.discover')}</p>
          </LocalizedLink>
        </header>
      </div>
      <div className="container text-xl text-black md:text-2xl">
        <div
          className={`flex flex-wrap items-center md:flex-nowrap lg:h-screen ${animateSlideInClass(
            isInViewSafe,
          )}`}
          ref={animatedSafe}
          id="safe"
        >
          <div className="px-4 sm:px-8">
            <h2 className="text-3xl font-bold text-reacteev-blue md:text-left">
              {tEvents('safe.title')}
            </h2>
            <p className="whitespace-pre-line text-xl">{tEvents('safe.description')}</p>
            <div className="text-center">
              <a
                href="#meet"
                className={`button my-5 inline-block whitespace-pre-line border border-solid border-current px-6 py-3 text-base font-medium ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
                rel="nofollow"
                title={tEvents('safe.button-text')}
              >
                {tEvents('safe.button-text')}
              </a>
            </div>
          </div>
          <div className="mt-3">
            <StaticImage
              src="../images/events/safe_submit-sorrento_italy-07_10-04-2025.png"
              alt={tEvents('safe.alt-image')}
            />
          </div>
        </div>
        <section className="px-4 text-center sm:px-8" id="clients">
          <h2 className="text-3xl font-bold text-reacteev-blue">{tEvents('clients.title')}</h2>
          <p className="my-8 whitespace-pre-line">
            {
              <Trans
                t={tEvents}
                i18nKey="clients.description"
                components={{ blue: <span className="text-reacteev-blue" /> }}
              >
                clients.description
              </Trans>
            }
          </p>
          <Banner className="bg-gray-200" clientImages={nodeFile} />
        </section>
        <div
          className={`flex flex-col items-center justify-center lg:h-screen ${animateSlideInClass(
            isInViewReacteev,
          )}`}
          ref={animatedReacteev}
          id="reacteev"
        >
          <h2 className="text-3xl font-bold text-reacteev-blue">{tEvents('reacteev.title')}</h2>
          <div className="flex flex-wrap items-center gap-3 md:flex-nowrap">
            <div className="whitespace-pre-line px-4 leading-snug sm:px-8 md:w-3/5 md:pr-0">
              {
                <Trans
                  t={tEvents}
                  i18nKey="reacteev.description"
                  components={{ blue: <span className="font-bold text-reacteev-blue" /> }}
                >
                  reacteev.description
                </Trans>
              }
            </div>
            <div className="mt-3 md:w-2/5">
              <StaticImage
                src="../images/events/reactors.png"
                alt={tEvents('reacteev.alt-image')}
              />
            </div>
          </div>
        </div>
        <div
          className={`px-4 sm:px-8 lg:h-1/2-screen ${animateSlideInClass(isInViewMeet)}`}
          ref={animatedMeet}
          id="meet"
        >
          <h2 className="whitespace-pre-line text-3xl font-bold text-reacteev-blue">
            {tEvents('meet.title')}
          </h2>
          <p className="whitespace-pre-line text-center">{tEvents('meet.description')}</p>
          <div className="flex flex-wrap items-center justify-center gap-x-4">
            <MeetButton
              href={tEvents('meet.button-for-summit-link')}
              pseudoClass={`${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            >
              {tEvents('meet.button-for-summit')}
            </MeetButton>
            <TallyButton
              tallyId={tEvents('meet.tally-for-debrief')}
              text={tEvents('meet.button-for-debrief')}
              colorClass={`my-5 border border-solid border-current font-medium text-center whitespace-pre-line ${Colors.WHITE_ON_BLACK} ${Colors.WHITE_ON_BLACK_PSEUDO_CLASS}`}
              noIcon
            />
          </div>
        </div>
        <div
          className={`mb-4 flex flex-col items-center px-4 sm:mb-8 sm:px-8 ${animateSlideInClass(
            isInViewSponsor,
          )}`}
          ref={animatedSponsor}
          id="sponsor"
        >
          <h2 className="text-3xl font-bold text-reacteev-blue">{tEvents('sponsor.title')}</h2>
          <StaticImage
            className="max-w-[300px]"
            src="../images/events/SAFe_Partner_Badge_Bronze.png"
            alt={tEvents('sponsor.alt-image')}
          />
          <MeetButton
            href="https://scaledagile.com/partner-finder/partners/0010W00002BTmrTQAT/"
            pseudoClass={`${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
          >
            {tEvents('sponsor.button-text')}
          </MeetButton>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default OurCurrentSponsoring;
